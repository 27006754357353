import React, { useState } from 'react';
import axios from 'axios';

import Layout from '../../components/Layout';
import Personal from '../../components/Personal';

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Landlord = () => {
  const [personalInfo, setPersonalInfo] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [info, setInfo] = useState({
    propertyManager: '',
    backgroundChecks: '',
    sameAddress: '',
    city: '',
    state: '',
    zip: '',
    address: '',
    desiredEffectiveDate: '',
  });
  const onSubmit = async () => {
    try {
      await axios.post(`${API_URL}/quote`, {
        personalInfo,
        info,
      });
      setSubmitted(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
  };

  return (
    <Layout fullMenu>
      <article id="main">
        <header style={{ height: 300 }}>
          <h2>Landlord Insurance</h2>
        </header>
        {submitted ? (
          <section className="wrapper style5">
            <h3 style={{ textAlign: 'center' }}>
              Thanks for submitting your info - Agency Insurance will be in
              contact with you shortly!
            </h3>
          </section>
        ) : (
          <section className="wrapper style5">
            <div
              className="inner"
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              <h3 style={{ width: '100%', marginBottom: 0 }}>Personal Info</h3>
              <Personal
                onChange={setPersonalInfo}
                showAddress={false}
                showOccupation={false}
                showComments={false}
              />
              <div style={{ width: '100%' }}>
                <h5>Property to Insure</h5>
                <>
                  <label
                    style={{ width: '100%', marginTop: 0, marginRight: 20 }}
                  >
                    Street Address*
                    <input
                      type="text"
                      name="address"
                      id="address"
                      onChange={(e) => onInfoChange('address', e.target.value)}
                    />
                  </label>
                  <label
                    style={{
                      width: '30%',
                      minWidth: 300,
                      marginTop: 0,
                      marginRight: 20,
                    }}
                  >
                    City*
                    <input
                      type="text"
                      name="city"
                      id="city"
                      onChange={(e) => onInfoChange('city', e.target.value)}
                    />
                  </label>
                  <label
                    style={{
                      width: '30%',
                      minWidth: 300,
                      marginTop: 0,
                      marginRight: 20,
                    }}
                  >
                    State*
                    <input
                      type="text"
                      name="state"
                      id="state"
                      onChange={(e) => onInfoChange('state', e.target.value)}
                    />
                  </label>
                  <label
                    style={{
                      width: '30%',
                      minWidth: 300,
                      marginTop: 0,
                      marginRight: 20,
                    }}
                  >
                    Zip Code*
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      onChange={(e) => onInfoChange('zip', e.target.value)}
                    />
                  </label>
                </>
                <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
                  Do you use a property manager?
                  <br />
                  <input
                    type="radio"
                    id="propertyManagerYes"
                    name="propertyManager"
                    onChange={() => onInfoChange('propertyManager', 'yes')}
                  />
                  <label htmlFor="propertyManagerYes">Yes</label>
                  <input
                    type="radio"
                    id="propertyManagerNo"
                    name="propertyManager"
                    onChange={() => onInfoChange('propertyManager', 'no')}
                  />
                  <label htmlFor="dogNo">No</label>
                </label>
                <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
                  Do you perform background checks?
                  <br />
                  <input
                    type="radio"
                    id="backgroundChecksYes"
                    name="backgroundChecks"
                    onChange={() => onInfoChange('backgroundChecks', 'yes')}
                  />
                  <label htmlFor="backgroundChecksYes">Yes</label>
                  <input
                    type="radio"
                    id="backgroundChecksNo"
                    name="backgroundChecks"
                    onChange={() => onInfoChange('backgroundChecks', 'no')}
                  />
                  <label htmlFor="dogNo">No</label>
                </label>
                <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
                  Desired Effective Coverage Date
                  <input
                    type="date"
                    name="desiredEffectiveDate"
                    id="desiredEffectiveDate"
                    onChange={(e) =>
                      onInfoChange('desiredEffectiveDate', e.target.value)
                    }
                  />
                </label>
              </div>
            </div>
            <div className="inner">
              <button onClick={onSubmit}>Submit</button>
            </div>
          </section>
        )}
      </article>
    </Layout>
  );
};

export default Landlord;
